@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600;700&family=Roboto:wght@700&family=Work+Sans:wght@600&display=swap');

html {
  overflow: scroll;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

iframe {
  display: none;
}

.swiper {
  left: 125px;
  overflow: visible;
}
.MuiAutocomplete-paper {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-left: 1px solid #6BB3F2 !important;
  border-right: 1px solid #6BB3F2 !important;
  min-width: calc(100% + 2px);
  margin-left: -1px;
}

